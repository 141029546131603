import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../layouts/Site"
import { Content } from "../components/Content"
import { AssertPage } from "../components/AssetContext"
import { actionClass } from "../components/CallToAction"
import GoogleMapReact from "google-map-react"
import { Logo } from "../components/Logo"
import { HeaderPage, PageWidth, Section } from "../components/Containers"
import { Image } from "../components/Image"
import { pageHeadingStyles } from "../components/Headings"
import { Helmet } from "react-helmet";

export default function ContactPage({ location }) {
  const { page, defaultMetadata } = useStaticQuery(query)

  return <Layout title={page.name} darkFooter>
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    {page.contents.map(createTopLevelComponent)}
  </Layout>
}

function createTopLevelComponent(content) {
  if (content.key === "contact: conversation")
    return <ConversationPage content={content} />

  if (content.key === "contact: map")
    return <FindUsPage content={content} />

  if (content.key === "contact: address")
    return <AddressPage content={content} />
}

function ConversationPage({ content }) {
  return <HeaderPage hideControl>
    <Section cols='auto'>
      <Content content={content} richTextStyles={pageHeadingStyles} />
      <ContactForm />
    </Section>
  </HeaderPage>
}

function ContactForm() {
  return <form id="contact-form" action="https://formspree.io/f/xrgojrnd" method="POST">
    <input type="hidden" name="_subject" value="[xodiac.ca] Contact message" />
    <InputField type="text" name="name" placeholder="Your name" />
    <InputField type="email" name="_replyTo" placeholder="Your email address" />
    <InputField name="message" placeholder="What can we help you with? Don't be shy and just ask away..." rows="7" />
    <div className="text-right">
      <button type='submit' id='contact-button' className={actionClass()}>Send Message</button>
    </div>
  </form>
}

function InputField({ type, name, placeholder, rows }) {
  const className = "block w-full mb-4 border-gray border rounded-xl focus:border-2 focus:rounded-xl px-8 py-4"

  if (rows)
    return <textarea name={name} placeholder={placeholder} required rows={rows} className={className}></textarea>
  return <input type={type} name={name} placeholder={placeholder} required className={className} />
}

function FindUsPage({ content }) {
  return <PageWidth className='bg-primary'>
    <Section>
      <Content content={content} className='text-page text-center' richTextStyles={pageHeadingStyles} />
      <div className='w-full h-1/2s md:h-1/3s'>
        <Map />
      </div>
    </Section>
  </PageWidth>

  function Map() {
    const location = {
      lat: 43.648541,
      lng: -79.381785,
    }

    return <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLEMAPS_TOKEN }}
      defaultCenter={location}
      defaultZoom={13}>
      <Logo type="icon" size='2rem' className='-ml-4 -mt-4'
            lat={location.lat}
            lng={location.lng}
      />
    </GoogleMapReact>
  }
}

function AddressPage({ content }) {
  return <PageWidth className='bg-primary'>
    <Section className='flex justify-center'>
      <Image image={content.image} className='w-24 mr-6' />
      <Content content={content} className='text-page' />
    </Section>
  </PageWidth>
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "contact"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content        
      }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`